import React from "react"
import {graphql} from 'gatsby'
import Seo from '../components/Seo'
import ContactForm from "../components/ContactForm"
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import Faq from "../components/Faq"
import CtaSection from "../components/CtaSection"

export default function Home({data}) {
    const faq = data.allFaqPrimaryJson.nodes;

    return (
    <>
        <Seo title="Kontakt"
            description="Jestem programistą freelancerem z Bydgoszczy i tworzę strony internetowe oraz grafikę. Zapraszam do kontaktu." />
        <Layout title="Kontakt">
            <section className="section">
                <div className="container">

                    <SectionTitle title="Kontakt"
                        subtitle="Masz pytania? Napisz do mnie. Przed wyceną i rozpoczęciem współpracy, chętnie odpowiem na każde pytanie." />

                    <div className="row gy-5 gy-lg-0">

                        <div className="col-12 col-md-6">
                            <ContactForm />
                        </div>

                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                            <img src="/contact.svg" className="img-fluid" alt="Potrzebujesz strony internetowej? Napisz do mnie" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="section section--with-bg">
                <div className="container">
                    <SectionTitle title="FAQ" subtitle="Poniżej znajdują się odpowiedzi na często zadawane pytania." />
                    <Faq data={faq} />
                </div>
            </section>

            <CtaSection />
        </Layout>
    </>
    )
}

export const query = graphql`
    query FaqPrimaryQuery {
        allFaqPrimaryJson {
            nodes {
              description
              title
            }
        }
    }
`