import React from 'react'

const FaqItem = ({title, description}) => {
    return (
        <>
        <div className="faq__item card">
            <div className='card-body'>
                <h5 className="faq__title">{title}</h5>
                <p className="faq__description">{description}</p>
            </div>
        </div>
        </>
    )
}

export default function Faq({data}) {
    return (
        <>
        <div className="faq">
            { data.map(item => <FaqItem key={item.title} title={item.title} description={item.description} />) }
        </div>
        </>
    )
}
