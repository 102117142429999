import React from 'react'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocket } from '@fortawesome/free-solid-svg-icons'

export default function CtaSection({hasBackground}) {
    const sectionClasses = hasBackground ? 'section section--lower-padding section--with-bg' : 'section section--lower-padding';

    return (
        <section className={sectionClasses}>
            <div className="container text-center">
                <div className="row">
                    <div className="col-12">
                        <h3>Potrzebujesz strony internetowej?</h3>
                        <p className="lead m-0">Wypełnij brief, a przygotuję dla Ciebie indywidualną wycenę.</p>
                        <Link to="/brief" className="btn btn-secondary btn--with-icon mt-3" title="Wycena">
                            <FontAwesomeIcon icon={faRocket} />
                            Wypełnij brief
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
