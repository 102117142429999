import React, { useState } from "react";
import useValidator from "../hooks/useValidator";
import Alert from './Alert'
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import useEmail from "../hooks/useEmail"

export default function ContactForm() {
    const [state, setState] = useState({
        username: '',
        email: '',
        message: '',
        rodo: false,
    });
    const { username, email, message, rodo } = state;
    const values = {username, email, message, rodo};
    const validator = useValidator();
    const emailHook = useEmail();

    // handle field change
    const handleChange = input => e => {
        const { value, checked } = e.target;

        setState(state => {
            return {
                ...state,
                [input]: input === 'rodo' ? checked : value,
            }
        });
    }

    const sendEmail = () => {
        if(!validator.current.allValid()) {
            return validator.current.showMessages();
        }

        emailHook.send(process.env.GATSBY_EMAILJS_TEMPLATE_ID_CONTACT_FORM, values)
    }

    return (
        <>
        <h4 className="contact-form__title mb-3">Napisz do mnie</h4>
        <div className="row">
            <div className="col-md-6 form-group"> 
                <input 
                    onChange={handleChange('username')} 
                    onBlur={()=>validator.current.showMessageFor('username')}
                    type="text" name="username" className="form-control" id="username" placeholder="Imię" value={values.username} />
                    {validator.current.message('username', values.username, 'required|alpha_space_fixed|min:3')}
            </div>
            <div className="col-md-6 form-group mt-3 mt-md-0">
                <input 
                    onChange={handleChange('email')} 
                    onBlur={()=>validator.current.showMessageFor('email')}
                    type="email" className="form-control" name="email" id="email" placeholder="E-mail" value={values.email} />
                    {validator.current.message('email', values.email, 'required|email|min:7')}
            </div>
        </div>
        <div className="form-group mt-3">
            <textarea className="form-control" id="message" name="message" rows="5" placeholder="Wiadomość" 
            onChange={handleChange('message')} onKeyUp={()=>validator.current.showMessageFor('message')} value={values.message} ></textarea>
            {validator.current.message('message', values.message, 'required|min:6')}
        </div>
        <div className="form-check has-validation">
            <input 
                onChange={handleChange('rodo')}
                className="form-check-input"
                type="checkbox" name="rodo" id="rodo" checked={values.rodo} />
            <label className="form-check-label contact-form__label contact-form__label--is-small" htmlFor="rodo">
            Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu w celu udzielenia odpowiedzi na przesłane zapytanie przez administratora strony Macieja Tymosiaka.
            </label>
            {validator.current.message('rodo', values.rodo, 'required|accepted')}
        </div>

        <div className="mt-3">
            <div className={emailHook.emailSent ? 'd-none' : 'd-flex justify-content-end'}>
                {!emailHook.isSending && <button className="btn btn-secondary btn--with-icon" onClick={sendEmail}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    Wyślij
                </button>}
                {emailHook.isSending && !emailHook.emailSent ? <Spinner/> : null}
            </div>
            {emailHook.emailSent && <Alert alertClass="alert-success" message="Wiadomość została wysłana 🎉 Niedługo się odezwę 😎" />}
            {(!emailHook.emailSent && emailHook.hasError) && <Alert alertClass="alert-danger mt-3" message="Wystąpił błąd. Wiadomość nie została wysłana." />}
        </div>
        </>
    );
}